.dnd-handle {
  height: 33px;
  width: 33px;
  position: absolute;
  display:flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #34c4a4;
  left: 216px;
  top: 24px;
  cursor: move;
  z-index: 999;
  border-radius: 100px;
  background-color: #F4F4F4;
}

.dnd-handle > svg {
  width: 20px !important;
  height: 20px !important;
}
