
input {
  height: 42px;
}

.contract {
  @apply p-8;
}

.innerContract {
  @apply flex flex-col gap-8 bg-white p-8 pt-6;
}

.innerContract h2 {
    @apply mb-2
}

.contractLayout {
  @apply flex flex-col bg-gray-100 font-sans text-gray-800; min-height: calc(100vh - 114px);
}

.contractHeader {
  @apply px-7 py-5 border-b border-gray-930 flex justify-between items-center;
  height: 83px;
  & h2 {
    @apply text-lg font-medium leading-none;
  }
  & p {
    @apply mt-1 text-sm font-normal text-gray-600;
  }
}

.contractHeaderButtons {
  @apply flex self-stretch items-center;
}

.attachmentPage {
  @apply overflow-auto pb-64 h-screen;
}

.attachmentCardsRow {
  @apply px-5 pb-5;
  & > div {
    @apply mx-auto mt-8 mb-10 max-w-screen-lg flex flex-wrap justify-center gap-4 lg:gap-5;
  }
}

.attachmentCard {
  @apply p-0 shadow-none border border-solid border-gray-300;
  & .downloadAttachment {
    @apply uppercase w-full my-2;
    max-height: 36px;
  }
  & .removeAttachment {
    max-height: 36px;
    & svg {
      @apply w-4 !important;
      height: 19px !important;
    }
  }
  & .attachmentCardFooter {
    @apply px-4 pb-4 items-center;
    & svg {
      @apply text-pink-100;
    }
    & label {
      @apply -mr-2;
    }
  }
  & .attachmentCardHeader svg {
    @apply inline-block max-w-full;
  }
  & .attachmentSwitchLabel {
    @apply flex items-center;
    & svg {
      @apply h-auto w-auto ml-1;
    }
  }
}

.cardSectionColumn {
  @apply flex flex-col gap-8
}

.clause.cardSectionColumn {
  @apply gap-0
}

.clause.cardSectionColumn .cardSection:not(:last-of-type) {
  @apply mb-8
}

.cardSection {
  @apply flex flex-col gap-4 border border-solid border-gray-200 p-5
}

.contractMenu {
  @apply p-5 -m-5;
  & svg {
    fill: #c9caca;
  }
}

.optionItems > div{
  @apply my-3
}

.customLabel:after {
  content: "*";
  @apply text-pink-600 ml-1
}

.contractMenu:hover svg {
  fill: #3d3d3d;
}

.signersSection {
  @apply h-full px-5 pb-5;
  & > div {
    @apply h-full flex flex-col justify-between mx-auto mt-8 mb-10 max-w-screen-lg;
  }
}

.signersListItem {
  @apply mb-4 py-5 rounded-lg border-none;
  height: 78px;
  & b {
    @apply flex font-semibold text-sm mb-1;
  }
  & p {
    @apply text-xs font-medium text-gray-600 leading-none;
  }
  & .userInfo {
    @apply flex flex-row items-center;
    width: 305px;
  }
  & .ant-divider-vertical {
    @apply mx-4;
  }
}

.signersListItemBody {
  @apply w-6/12 mr-8;
  & h5 {
    @apply text-xs font-medium text-gray-600 mb-1 leading-none;
  }
}

.mendatoryField {
  @apply text-pink-600;
}

.partyModalCard {
  @apply bg-gray-210 p-5 rounded-lg;
  &:not(:last-child) {
    @apply mb-4;
  }

  & .partyModalForm {
    @apply p-5 mt-3 flex flex-wrap rounded-lg bg-white;
    & label {
      @apply text-xs text-gray-700;
    }
    & input {
      @apply h-8 bg-gray-210 rounded-sm border border-transparent;
      &:focus {
        @apply border border-gray-600;
      }
      &:hover {
        @apply border-transparent;
      }
    }

    & button {
      @apply h-8;
    }
  }

  & .radioContainer {
    @apply flex items-center mr-8;
    & [role="radio"] > div {
      @apply bg-white w-5 h-5;
      min-width: 0;
    }
    & label {
      @apply ml-0;
    }
  }
}

.partyModalTitle {
  @apply flex font-bold items-center justify-between mb-4;
  & [role="row"] {
    @apply flex;
  }
  & svg {
    @apply h-5 w-5 mr-3 text-pink-600;
  }
}

.partyHeaderRadio {
  @apply flex;
}

.signersListFooter {
  @apply w-2/12;
  & button svg {
    @apply w-4 !important;
  }
  & .signersListFooterButtons {
    @apply flex flex-row items-center;
    & > div > div {
      @apply self-center;
      min-width: 0;
    }
  }
}

.summarySection {
  @apply px-5 pb-5;
  & > div {
    @apply mx-auto mt-8 mb-10 max-w-screen-lg;
  }
}

.summaryCardsRow {
  @apply flex flex-wrap justify-center gap-5;

  &:first-of-type {
    @apply mb-5;
  }
}

.summaryCard {
  @apply flex-grow border-none shadow-none mt-0 rounded-none;
  & h3 {
    @apply text-xl font-normal leading-none;
  }
  & button {
    @apply mt-3;
  }
}

.contractSummaryCard {
  @apply p-5 border-none shadow-none mt-0 rounded-none;
  width: calc(33.3% - 0.9rem);
  & h3 {
    @apply text-xl font-normal leading-none;
  }
  & button {
    @apply mt-3;
  }
}

.borderedCard {
  @apply bg-transparent border border-solid;
  border-color: #bdbdbd;
}

.summaryCardSigning {
  width: 100%;
  @apply px-6 py-4 mt-0 border-none shadow-none rounded-none;
  & h3 {
    @apply text-xl font-normal leading-6;
  }
}

.summaryCardSigningBody {
  @apply mt-5;
}

.summaryList {
  @apply flex flex-row items-center justify-between gap-2 py-2;
  &:not(:last-child) {
    @apply border-b border-gray-200;
  }
  & .tenant {
    @apply overflow-hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
}

.attachments {
  @apply justify-start;
  & svg {
    @apply w-4 h-4;
    flex-shrink: 0;
  }
}

.summaryCardsHeader {
  @apply mb-4 items-center font-robotoSlab flex-row;
}
.signingCardsHeaderTitle {
  @apply mb-1 items-center font-robotoSlab flex-row;
}

.contractsContainer {
  @apply px-5 pb-5;
  & > div {
    @apply bg-white p-6 mt-8 mb-10 border border-solid border-gray-930 mx-auto max-w-screen-lg;
  }
}

.contractSection {
  @apply mb-10 p-3 border border-solid border-gray-930 rounded;
  & h2 {
    @apply font-roboto text-base font-medium mb-3;
  }
  & svg {
    @apply w-4;
  }
}

.notificationBar {
  @apply bg-pink-90 flex px-6 py-4;
}

.contractRentalPremiseClause {
  @apply flex flex-col w-full;
  & svg {
    @apply h-5;
  }
}

.contractPdfClause {
  & svg {
    @apply h-5;
  }
}

.contractDatesClause {
  @apply flex flex-col gap-8 w-full;
  & .datesInputRow {
    @apply flex gap-8 w-full;
    & > div {
      width: 33.3%;
    }
  }
}

.contractDatesClauseHeader {
  @apply flex flex-row gap-4 items-center;
  & svg {
    @apply h-5;
  }
}

.rentalPeriodTenant {
  @apply mb-6;
  & .datesInputRow {
    @apply flex gap-8 w-full;
    & > div {
      width: 33.3%;
    }
  }
}

.datePickerHidden {
  @apply p-0; border: none;
  & div {
    display: none;
  }
}

.contractCard {
  @apply mb-8 mt-3 p-5 pb-2 border border-gray-930 bg-white;
}

.insideCard {
  @apply mb-8 mt-3 p-5 border border-gray-930;
}

.toggleHeader {
  @apply flex justify-between mt-2 items-center
}

.cardOptionsModal {
  @apply flex flex-col;

  & .option {
    @apply transition duration-500 ease-in-out shadow-none border h-70;
    width: 30%;
    &:hover {
      @apply shadow;
    }
    & svg {
      @apply w-16 h-16 mb-3;
    }
    & h3 {
      @apply w-full pb-2 font-normal border-b border-solid border-gray-200 leading-tight;
    }
    & p {
      @apply overflow-hidden h-10;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }
  }
  & .modalSecondaryButton {
    @apply mt-3 h-10 px-4 bg-white border-gray-940 font-medium text-gray-700 uppercase;
    &:hover {
      @apply text-white bg-pink-600 border-white;
      & svg {
        @apply text-white;
      }
    }
    &:focus {
      @apply px-4 text-white bg-pink-600 border-white;
      & svg {
        @apply text-white;
      }
    }
    & svg {
      @apply mb-0 h-3 w-3 text-gray-700;
    }
  }
}

.modalOptionsRow {
  @apply flex flex-row gap-6 flex-wrap justify-center;
  max-width: 750px;
}

.multipleOptionsModal {
  @apply w-auto h-12;
  overflow-x: auto;
}

.rentalPeriodOptionsContainer {
  @apply w-full;
}

.rentalPeriodOptionsHeader {
  @apply mt-6 mb-5 w-full flex items-center justify-between;
  & p {
    @apply font-semibold text-base;
  }
  & span {
    @apply mr-2;
  }
}

.rentalPeriodOptions {
  @apply flex flex-col w-1/3 mb-2;
}

.rentalRadioOptions {
  @apply flex;
  & > div {
    @apply mr-5;
  }
  & [role="radio"] {
    @apply mb-2;
  }
}

.headingSection {
  @apply mb-20;
}

.contractTitle {
  @apply text-2xl font-normal font-robotoSlab whitespace-nowrap overflow-hidden;
  text-overflow: ellipsis;
  max-width: 500px;
}

.contractSpinner {
  @apply mx-0 my-auto w-full mt-4;
}

.navHeaderContract {
  @apply bg-white font-medium static w-full top-0 flex-col px-6 py-2 pt-6;
}

.navHeaderContractInfo {
  @apply flex items-center justify-between w-full;
  & > div {
    @apply flex items-center;
  }
}

.navHeaderContractTabs {
  @apply flex items-center justify-between w-full mt-4;
}

.activityLogModal {
  @apply bg-white;
}

.activityLogModalHeader {
  @apply flex justify-between items-start;
  & .logoContainer {
    @apply bg-green-400 flex justify-center items-center;
    height: 122px;
    width: 145px;
    & img {
      height: 47px;
    }
  }
  & .titleContainer {
    @apply bg-green-300 flex justify-between flex-grow;
    height: 122px;
    & div {
      @apply font-roboto text-2xl justify-center flex flex-col pl-8;
    }
    & span {
      @apply font-bold;
    }
    & p {
      @apply text-2xl font-normal;
    }
    & button {
      @apply cursor-pointer m-2 self-start;
    }
  }
}

.activityLogModalBody {
  @apply p-8 pt-12;
}

.headerSwitch:focus > div:after {
  @apply border-none !important;
}

div.partyFormRow {
  @apply -mx-4 flex flex-wrap justify-start;
  & > div {
    @apply mx-4;
    width: calc(50% - 32px);
  }
}

.fullWidth {
  width: 100% !important;
}

div.datesRow {
  @apply mt-5 flex justify-start;
  & > div {
    @apply mr-8;
  }
}

.sendContractForm {
  @apply pt-3 pb-1;
  & ul {
    @apply flex flex-wrap items-start mb-3 font-medium;
  }
  & svg {
    @apply h-4 w-4;
  }
  & button {
    @apply p-1 ml-1 h-8;
    &:focus {
      @apply border-none;
    }
  }
  & .customInput {
    @apply ml-2 mr-1;
    width: 70%;
    min-width: 280px;
    & input {
      @apply h-8;
    }
  }
  & label {
    @apply h-8 flex items-center whitespace-nowrap;
    width: 55px;
  }
  & span {
    @apply ml-2 mr-1;
  }
  & li {
    @apply flex items-center;
  }
  & .inputContainer {
    @apply flex flex-wrap items-center;
    flex-grow: 1;
    max-width: 400px;
    gap: 0.75rem 0;
  }
}

.sameHeightFields {
  @apply w-1/2 flex flex-col;

  & > div {
    @apply mt-auto;
  }
}

.vatPercent {
  @apply inline;
  & > div {
    @apply mt-0;
    margin-top: -4px;
  }
}

.partyModalCard {
  @apply bg-gray-210 my-8 p-5
}

.partyModalInnerCard {
  @apply flex-grow flex flex-col gap-4 bg-white p-5 rounded-lg
}

.partyModalInnerCard:not(:last-of-type) {
  @apply mb-6
}


.cards-list {
  @apply mx-auto w-full;
  max-width: 1024px;
}

.cards-list button{
  @apply px-2 py-1 border-0 h-auto !important
}

.cards-tab-list-item {
  @apply flex items-center justify-center mb-4 px-4 py-5 rounded-lg bg-white;
  height: 78px;
}

.cards-tab-list-item-index {
  @apply flex items-center
}

.addendum-list-name {
  @apply font-medium
}

.addendum-list-status {
  @apply text-sm text-gray-600 font-medium; text-transform: capitalize;
}

.cards-info-row {
  @apply flex items-center
}

.cards-info {
  width: 140px;
}

.list-element-button {
  @apply h-auto p-2;
  min-width: auto!important;
}

.list-name-element {
  @apply text-gray-910 font-medium overflow-hidden whitespace-nowrap mr-8;
  text-overflow: ellipsis;
  max-width: 400px;
}

.list-element-button svg {
  @apply w-auto h-4 !important
}

.list-element-button:hover {
  @apply bg-transparent !important;
}

.list-element-button:first-of-type:hover svg{
  @apply text-green-700;
}

.list-element-button:last-of-type:hover svg{
  @apply text-red-600;
}

.list-element-button.eye svg{
  @apply w-5
}

.list-element-button.eye:hover svg{
  @apply text-blue-500
}

.list-element-button.trash:hover svg{
  @apply text-pink-600
}

.list-element-button.reorder svg {
  @apply w-5
}

.list-element-button.reorder:hover svg {
  @apply text-pink-600
}

.cards-actions-row {
  /* @apply flex justify-end; */
  @apply flex justify-end;
  width: 130px;
}

/* form layout */

.row {
  @apply flex gap-8 items-start;
}

.col {
  @apply flex flex-col flex-grow; flex-basis: 0;
}

.totalAreaField {
  @apply flex-grow-0 !important;

 > div {
    text-wrap: nowrap;
  }
}

.dropdownField {
  @apply flex-grow flex flex-col; flex-basis:0
}

/* reload */

.reloadButton {
  transition: 300ms linear;
}

.reloadButton:hover {
  cursor: pointer;
  transform: rotate(40deg);
  transition: 300ms linear;
}

.padding-12 {
  padding: 12px !important;
}

.margin-top-neg-12 {
  margin-top: -12px;
}
