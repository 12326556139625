.icon-small-btn {
  padding-left: 5px !important;
  padding-right: 5px !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  min-width: 0 !important;
  height: 25px !important;
}
.icon-small-btn > svg {
  width: 15px !important;
  height: 15px !important;
}
