.menu-grid {
  grid-template-columns: 2rem auto;
}

.max-height-16r-scroll {
  max-height: 16rem;
  overflow-y: auto;
}

.client-search-input > input{
  max-height: 1.8rem;
  border: 0px;
  background-color: #f9f9f9;
}

.client-search-input > input::placeholder{
  color: black;
  font-family: Roboto;
  font-size: 1rem;
  font-weight: 700;
}

.client-search-input > input:focus::placeholder{
  color: grey;
}
