.ant-picker {
  height: 42px;
  font-size: 18px;
}

.ant-picker:hover:not(:disabled) {
  cursor: pointer;
}

.input-field-label-ro {
  display: block;
  color: #6F6F6F;
  font-size: 13px;
}

.sub-clause-header {
  font-family: Arial;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
}

.sub-clause-header:not(:first-of-type) {
 margin-top: 0.5rem;
}

.contract-centered {
  max-width: 1024px;
  min-width: 768px;
  width: 1024px;
  margin: auto;
}

.no-select {
  user-select: none;
}

.flex-grow-2 {
  flex-grow: 2;
}

.flex-grow-3 {
  flex-grow: 3;
}

.flex-grow-4 {
  flex-grow: 4;
}

.flex-grow-5 {
  flex-grow: 5;
}

.flex-grow-6 {
  flex-grow: 6;
}

.flex-grow-7 {
  flex-grow: 7;
}

.checkbox-input {
  width: 100%;
  position: relative;
}

.checkbox-input input {
  width: 100%;
  text-align: left;
}

.custom-checkbox {
  display: flex;
  align-items: center;
  gap: 10px;
  position: absolute;
  top: 0;
  right: 8px;
  bottom: 0;
}

.custom-checkbox:before {
  content: "";
  height: 20px;
  width: 2px;
  margin-top: 3px;
  background-color: #E3E4E4;
}

.custom-checkbox > div:last-child div {
  height: 16px;
  width: 16px;
  min-width: 16px;
}

.input-readonly {
  font-weight: 600;
}

.texteditor-readonly {
  .ql-toolbar {
    @apply hidden
  }
  .ql-toolbar.ql-snow + .ql-container.ql-snow {
    @apply border border-solid border-gray-300
  }
  .ql-editor {
    @apply font-normal text-gray-600
  }
}

