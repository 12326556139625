/*@import "antd/dist/reset.css";*/
/*@import "antd/dist/antd.css";*/
@import "./styles.css";
@tailwind base;
@tailwind components;
@tailwind utilities;


.kpy-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.kpx-14 {
  padding-left: 14px;
  padding-right: 14px;
}

.font-16 {
  font-size: 16px;
}
.font-14 {
  font-size: 14px;
}
.font-12 {
  font-size: 12px;
}

.klp-border1 {
  border-color: #c9caca;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  color: inherit;
}

h1 {
  @apply font-robotoSlab text-3xl font-normal text-gray-910;
}

h2 {
  @apply font-roboto text-xl font-semibold text-gray-910;
}

button.btnGhostSimple {
  &:focus {
    @apply border-none;
  }
  &:hover {
    @apply bg-transparent !important;
    opacity: 0.7;
  }
}

button.btnGhostUnderline {
  @apply h-auto min-w-0 mr-6 p-0 self-center text-gray-910 font-medium border-b border-solid border-t-0 border-l-0 border-r-0
         border-pink-600;
  &:hover {
    @apply bg-transparent !important;
  }
}

button:disabled {
  @apply border-transparent;
}

.border-focus:focus {
  @apply border-0 !important;
}

button:focus {
  @apply border !important;
}

input:focus {
  @apply bg-transparent border-pink-600 !important; outline: 0;
}

input:disabled {
  @apply bg-gray-210 border-gray-300 text-gray-500
}

input:disabled + .dropdownArrow svg{
  opacity: 0.5
}

.ql-editor:focus {
  @apply bg-gray-100 !important;
}

.ant-picker-focused {
  @apply border-pink-600 shadow-none;
}

.ant-picker:hover {
  @apply border-pink-600;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  @apply border-pink-600;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
  @apply border border-solid border-pink-600 bg-transparent text-gray-910;
}

.ant-picker-today-btn {
  @apply text-pink-600;
}

input[type="tel"] {
  @apply border !important;
}

.main-class {
  width: calc(100% - 300px);
}
.aside-class {
  width: 300px;
}

@media all and (min-width: 992px) {
  .main-class {
    width: calc(100% - 400px);
  }
  .aside-class {
    width: 400px;
  }
}

@media all and (min-width: 1400px) {
  .main-class {
    width: calc(100% - 400px);
  }
  .aside-class {
    width: 400px;
  }
}

.shoppingMallsDropdown {
  top: 62px !important;
  height: 450px;
  overflow: scroll;
}

.tenantCPDropdown {
  top: 62px !important;
}
.signatoryDropdown {
  top: 40px !important;
}
.versionsDropdown {
  top: 62px !important;
}
.fieldDropdown {
  top: 45px !important;
}

button:focus,
textarea {
  outline: none !important;
}

.primary-button:active {
  box-shadow: inset 0px 0px 3px black;
}
input[type="file"] {
  display: none;
}

.ant-switch-checked {
  background-color: #38dab6;
}

.ant-dropdown-menu {
  border-radius: 8px;
  border: 1px solid #c1c2c2;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
  max-height: 400px;
  overflow: auto;
}

.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before  {
  border-color: #E12764;
}

.ant-dropdown-menu-item {
  padding: 0.5rem;
}

.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
  background-color: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.88);
  border-color: transparent;
}

.ant-picker-dropdown .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner:hover {
  border-color: #E12764;
}

/* .ant-dropdown{
  top: 377px !important;
} */

.ql-editor:focus {
  background-color: #cdfae2;
}

.ql-editor ol,
.ql-editor ul {
  padding-left: 0;
}

.ql-editor ol li:not(.ql-direction-rtl),
.ql-editor ul li:not(.ql-direction-rtl) {
  padding-left: 1em;
}

.parent-scroll {
  overflow-y: auto;
}

.ql-clipboard {
  position: fixed !important;
}

.second-theme-color {
  color: #2cb08a !important;
}
.second-theme-color:hover {
  color: #2cb08a !important;
  background-color: #b9f0d3 !important;
}

.secondary-switch-unchecked > .track {
  background-color: #e1e1e1 !important;
}

[aria-disabled="true"].switch > .track {
  opacity: 0.5;
}

.secondary-switch-checked > .track {
  background-color: #34c3a4 !important;
}
.secondary-switch-checked > .track::after {
  border: 0 !important;
  /* border-color: #38dab6 !important; */
  margin-top: -1px !important;
  margin-left: -1px !important;
}
.secondary-switch-unchecked > .track::after {
  border: 0 !important;
  /* border-color: #38dab6 !important; */
  margin-top: -1px !important;
  margin-left: -1px !important;
}

main {
  @apply bg-gray-100 min-h-screen w-full;
}

.newContractBtn .spinner {
  @apply w-full -ml-5;
}

.green-button {
  @apply bg-green-700 border-0 !important;

  &:hover {
    @apply bg-green-800 !important;
  }
}

.datePicker-popup-hidden .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  @apply border-0;
}
