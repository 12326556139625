.attachments-tab-list-item {
  width: 60rem;
  height: 5rem;

  background-color: white;
  border-radius: .5rem;
  border-color: rgba(225, 225, 225, 1);
  border-width: 1px;
}

.attachments-tab-list-item-index {
  width: 4rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.attachments-name {
  @apply ml-16
}

.attachments-header-right {
  @apply flex items-center
}

.attachments-header-right h5, .attachments-header-right > div {
  width: 140px;
}

.attachments-list-header {
  @apply flex items-center justify-between w-full mx-auto mb-2;
  max-width: 1024px;
  padding-right: 140px;
}

.attachments-tab-list-item-subtitle {
  margin-bottom: 0.25rem;
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1;
  color: rgba(153, 153, 153);
}

.attachments-list-element-button {
  /* height: 23px !important; */
  min-width: 48px !important;

  padding: 4px !important;
}

.attachments-list-element-button svg {
  width: 20px !important;
  height: 20px !important;
}
.attachments-list-element-button.eye svg path {
  fill: rgba(49, 130, 206, 1)
}
.attachments-list-element-button.eye:hover, .attachments-list-element-button.eye:active {
  background-color: rgba(49, 130, 206, .4) !important;
}
.attachments-list-element-button.eye:focus {
  border-color: rgba(49, 130, 206, 1) !important;
}

/* .parties-tab-person-name-text {
  font-size: .875rem;
  font-weight: 600;
}

.parties-tab-person-email-text {
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1;
  color: rgba(153, 153, 153, 1);
}

.parties-tab-modal-person-icon {
  margin-right: 0.75rem;
  height: 1.25rem;
  width: 1.25rem;
  --tw-text-opacity: 1;
  color: rgba(225, 39, 100, var(--tw-text-opacity));
} */
