.contractsContainer {
  @apply px-5 pb-5;
  & > div {
    @apply bg-white p-6 mt-8 mb-10 border border-solid border-gray-930 mx-auto max-w-screen-lg;
  }
}

div.partyFormRow {
  @apply -mx-4 flex flex-wrap justify-start;
  & > div {
    @apply mx-4;
    width: calc(50% - 32px);
  }
}

div.datesRow {
  @apply mt-5 flex justify-start;
  & > div {
    @apply mr-8;
  }
}

.turnoverRow {
  @apply my-5 items-center;
  & > p {
    width: 45%;
  }
}
.rentalPeriodOptions {
  @apply flex flex-col w-1/3 mb-2;
}

.rentalRadioOptions {
  @apply flex;
  & > div {
    @apply mr-5;
  }
  & [role="radio"] {
    @apply mb-2;
  }
}

.rentalPeriodOptionsContainer {
  @apply w-full;
}

.rentalPeriodOptionsHeader {
  @apply mt-6 mb-5 w-full flex items-center justify-between;
  & p {
    @apply font-semibold text-base;
  }
  & span {
    @apply mr-2;
  }
}
