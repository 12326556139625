.tabs > div:first-child {
  margin-left: 0 !important;
}

.selected-tab {
  font-weight: 600;
  color: rgba(225, 39, 100);
}

.selected-tab::after {
  content: "";
  background-color: rgba(225, 39, 100);
  bottom: -13px;
  width: 100%;
  position: absolute;
  height: 2px;
}
