nav {
  @apply bg-white fixed w-full z-30 top-0 flex flex-wrap items-center justify-between p-8 pt-6;
  & .userInfoSection {
    @apply hidden lg:inline-block text-right;
    & p:first-child {
      @apply font-roboto font-semibold;
    }
  }
  & .navButtons {
    @apply items-center hidden lg:flex;
    &.department { @apply relative border border-solid border-gray-940;
      > div {
        @apply h-full
      }
      & button { @apply pl-9 border-none h-full !important;
        &:focus{ @apply pl-9 !important}
      }
      & > svg  { @apply absolute; z-index:1;}
      & svg { @apply ml-2}
      & [role="menu"] { @apply p-2; top: 50px;
        & button { @apply pl-2;
          &:focus{ @apply pl-2}
        }
      }
    }
  }
}

.logoContainer {
  @apply flex items-center;
  & img {
    @apply h-10 w-16 mr-8;
  }
  & div {
    @apply hidden md:inline-block;
  }
  & h2 {
    @apply mb-1 text-lg text-pink-600 font-medium leading-6 whitespace-pre-line;
  }
  & a {
    @apply flex flex-row items-center;
    text-decoration: underline;
    text-decoration-color: #e12764;
  }
  & svg {
    @apply mr-1 text-pink-600;
  }
  & span:hover {
    @apply text-pink-600;
  }
}

.languageDropdown {
  @apply mr-6 flex items-center;
  & span {
    @apply ml-2;
  }
  & svg {
    &:last-child { @apply mb-1 ml-2; }
  }
  &:focus {
    @apply border-none !important;
  }
}

.languageDropdownItem {
  @apply mx-2 my-2;
}

.headerUserSection {
  @apply flex;
}

.headerSection {
  @apply mb-8;
}

.dashboardWrapper {
  @apply xl:container xl:mx-auto mx-6 pb-20 pt-32;
}

.menuSection {
  @apply flex items-center justify-between mb-4;
}

.newContract {
  @apply mr-16;
  & button {
    @apply w-48 justify-start px-4 font-medium;
    & span {
      @apply ml-2 uppercase;
    }
  }
  & svg {
    @apply w-4;
  }
  & .spinner {
    @apply bg-white;
  }
}

.filterSection {
  @apply w-48 mr-16;
}

.filter {
  @apply flex flex-col items-start mb-10;
  & h3 {
    @apply font-roboto text-base font-bold pb-2;
  }
}

.filterOption {
  @apply flex items-center cursor-pointer;
  & p {
    @apply mr-2;
  }
}

.filterHeader {
  @apply flex flex-col w-full;
  & .clientFilter {
    @apply flex flex-row items-center;
  }
}

.filterContent {
  @apply flex flex-col gap-2;
  max-height: 16rem;
  overflow-y: auto;
}

.filterContent::-webkit-scrollbar-track {
  @apply rounded-xl bg-gray-100;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.filterContent::-webkit-scrollbar {
  @apply w-2;
}

.filterContent::-webkit-scrollbar-thumb {
  @apply bg-gray-600 rounded-xl;
}

.divider {
  @apply border-gray-920 mt-1;
}

.tabsDivider {
  @apply border-gray-920 mt-3 mb-0;
}

.statusContainer {
  @apply flex items-center justify-between w-full gap-2
}

.status {
  @apply flex items-center;
  & svg {
    @apply mr-2 w-4 h-4;
  }
  & span {
    @apply text-xs;
  }
}

.thumbnailCard {
  @apply transition duration-500 ease-in-out shadow hover:shadow-lg w-56 h-64;
}

.headerDivider {
  @apply hidden lg:block;
  & div {
    @apply mx-8 h-12 bg-gray-920;
  }
}

.userMenu {
  @apply flex lg:hidden;
}

.cardViewDashboard {
  @apply flex -mx-3 flex-wrap;
  & .thumbnailCard {
    @apply mt-2 mx-3 mb-6 py-4 px-5 shadow-none rounded border border-solid border-gray-940;
    width: calc(25% - 24px);
    &:hover {
      @apply shadow-md;
    }

    @media only screen and (max-width: 1193px) {
      width: calc(33.33% - 24px);
    }

    @media only screen and (max-width: 1024px) {
      width: calc(50% - 24px);
    }
  }

  & .thumbnailCardHeader {
    & div {
      @apply flex justify-between;
    }
    & h3 {
      @apply mr-4 font-robotoSlab font-normal text-xl text-gray-910;
      line-height: 29px;
    }
    & svg {
      @apply h-5;
    }
  }
  & .thumbnailCardBody {
    @apply my-4;
    & div {
      @apply mb-0 text-xs font-normal text-gray-940 leading-tight;
    }
  }
}

.listViewDashboard {
  @apply overflow-hidden !important;
}

.viewToggle { @apply mb-4;
  & div {
    @apply flex justify-end;
  }
  & button {
    @apply ml-3;
  }
}

.tabsSection {
  @apply w-full;
}

.tabsRow {
  @apply flex -mx-2;
}

.dashboardView {
  @apply flex;
}

.statusInfo {
  @apply flex items-center;
  & span {
    @apply text-gray-600;
  }
}

.isLegacy:not(:empty) {
  @apply px-2 border border-solid border-gray-300 rounded-md font-medium text-green-700; font-size: 11px; padding-top: 3px; padding-bottom: 3px;
}

.contractMenu {
  @apply p-5 -m-5;
  & svg {
    fill: #c9caca;
  }
}

.contractMenu:hover svg {
  fill: #3d3d3d;
}

.cardOptionsModal {
  @apply flex flex-col;

  & .option {
    @apply transition duration-500 ease-in-out shadow-none border w-56 h-70;
    &:hover {
      @apply shadow;
    }
    & svg {
      @apply w-16 h-16 mb-3;
    }
    & h3 {
      @apply w-full pb-2 font-normal border-b border-solid border-gray-200 leading-tight;
    }
  }
  & .modalSecondaryButton {
    @apply mt-3 h-10 px-4 bg-white border-gray-940 font-medium text-gray-700 uppercase;
    &:hover {
      @apply text-white bg-pink-600 border-white;
      & svg {
        @apply text-white;
      }
    }
    &:focus {
      @apply px-4 text-white bg-pink-600 border-white;
      & svg {
        @apply text-white;
      }
    }
    & svg {
      @apply mb-0 h-3 w-3 text-gray-700;
    }
  }
}

.button.logOutButton {
  @apply border-gray-940 font-medium text-gray-700 uppercase;
  & svg {
    @apply mb-0 h-3 w-3 text-gray-700;
  }
  &:hover {
    @apply text-white bg-pink-600 border-white;
    & svg {
      @apply text-white;
    }
  }
  &:focus {
    @apply px-4 text-white bg-pink-600 border-white;
    & svg {
      @apply text-white;
    }
  }
}

.log-out-btn {
  border: 1px solid rgb(61, 61, 61) !important;
  color: rgb(61, 61, 61) !important;
}
.log-out-btn > svg {
  height: 16px !important;
  width: 16px !important;
}

.newAgreementBtn {
  & .loading {
    @apply bg-white;
  }
}

.headerSection .dashboardFilters { @apply flex flex-grow gap-6; height: 90px;
  & [role = "menu"] { @apply pr-4; top:46px;
    & button:hover { @apply bg-gray-200}
    & button:focus { @apply border-none}
  }
  & > div         { @apply w-full}
  & input         { @apply px-8 bg-transparent border-t-0 border-l-0 border-r-0 border-gray-700;
    &:focus       { @apply px-8 border-gray-700 !important; border-right: none; border-left: none; border-top: none;}
  }
  & .filterContainer { @apply  relative;
    & svg            { @apply absolute; top: 12px;  }
  }
  & input::-webkit-input-placeholder { @apply text-gray-900}
  & input:-moz-placeholder { @apply text-gray-900 }
  & button        { @apply bg-transparent border-t-0 border-l-0 border-r-0 border-gray-700;
    &:focus       { @apply border-gray-700; border-right: none; border-left: none; border-top: none;}
    & label        { @apply text-gray-900 font-normal}
  }
  & label         { @apply text-gray-600 font-medium}
}

.selectOption { @apply inline-flex items-center mt-1 py-px px-1 bg-pink-600 border-pink-600 rounded text-white;
  & svg       { @apply ml-2 text-white}
}

.contractName { @apply w-full; max-width:250px;
  & h2        { overflow: hidden; text-overflow: ellipsis; max-width: 240px; white-space: nowrap;}
}

.dashboardListHeader { @apply flex items-center w-full; margin-left: 306px;}

.onboardingModal { @apply flex-col h-full justify-between;
 & .deparmentDropdown { @apply relative mb-6 mx-auto my-0; max-width: 500px;
  .headerDivider { @apply hidden }
  .department { @apply flex items-center}
  &  button { @apply px-9;
    &:focus {
      @apply px-9;
    }
  }
  & svg { @apply absolute ml-2; z-index: 9; }

}
 & .infoSection  { @apply flex items-center justify-center;
    & svg        { @apply mr-2}
  }
  & img { @apply mb-6 mx-auto my-0 }
}

.onboardingMessage { @apply mb-6 text-center;
  & h3 { @apply text-2xl font-medium}
  & p { @apply text-xl mx-auto my-0 font-medium; max-width: 300px;}
}

.departmentDropdown {

}
