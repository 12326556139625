
.signers-list-header {
  @apply flex items-center justify-end w-full mx-auto mb-2;
  max-width: 1024px;
  padding-right: 140px;
}

.signers-list-header h5{
  width: 140px;
}

.party-icon-btn {
  @apply p-0 !important
}

.party-icon-btn + .party-icon-btn {
  border-top: 1px solid #DEDEDE;
}

.parties-tab-list-item-subtitle {
  margin-bottom: 0.25rem;
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1;
  color: rgba(153, 153, 153);
}

.parties-tab-person-name-text {
  @apply mb-1;
  font-size: .875rem;
  font-weight: 600;
}

.parties-tab-person-email-text {
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1;
  color: rgba(153, 153, 153, 1);
}

.parties-tab-modal-person-icon {
  margin-right: 0.75rem;
  height: 1.25rem;
  width: 1.25rem;
  --tw-text-opacity: 1;
  color: rgba(225, 39, 100, var(--tw-text-opacity));
}

.br-8px {
  border-radius: 8px;
}

.fw-700 {
  font-weight: 700;
}

#add-party-dropdown-container .dropdownArrow, #update-party-dropdown-container .dropdownArrow {
  top: 12px !important
}

#add-party-modal-window input, #update-party-modal-window input{
  height: 2rem;
  border-radius: 0.125rem;
  border-width: 1px;
  border-color: transparent;
  --tw-bg-opacity: 1;
  background-color: rgba(244, 244, 244, var(--tw-bg-opacity));
}
#add-party-modal-window .phone-prefix, #update-party-modal-window .phone-prefix{
  height: 2rem;
}
#add-party-modal-window .phone-field label, #update-party-modal-window .phone-field label{
  font-size: 12px;
}

.w-60-px {
  width: 60px
}

.w-45-px {
  width: 40px
}

.w-3r {
  width: 3rem
}

.digital-signing-switch-container {
  margin: auto auto 30px;
  border: 1px solid rgba(225, 225, 225);
  max-width: 1024px;
  width: 100%;
}
