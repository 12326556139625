.ant-notification .ant-notification-notice {
  padding-top: 10px !important;
  padding-left: 14px !important;
}

.ant-notification-notice-message {
  margin-bottom: 4px !important;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ant-notification-notice-description {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.take-me-there-button {
  margin-top: -12px;
  margin-right: -8px;
  cursor: pointer;

  padding: 2px;
  padding-left: 4px;
  padding-right: 4px;

  border-radius: 4px;
  border: 1px solid #e12764;
}

.take-me-there-button:hover {
  border-color: #fff;
  color: #fff;
  background-color: #e12764;
}
