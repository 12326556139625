.send-signing-btn > svg {
  height: 16px !important;
  width: 16px !important;
}

.send-signing-btn {
  position: relative;
}

.signing-invalid-fields {
  position: absolute;

  max-width: 200.550px;

  margin-top: 90px;
  /* margin-left: -10px; */

  color: white;
  background-color: #E12764;
  border: 2px solid #E12764;
}
