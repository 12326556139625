.vertical-line {
  background-color: rgb(201, 202, 202);
  width: 1.5px;
  height: 48px;
  margin-right: 30px;
  margin-left: 30px;
}

.my-lease-back-link {
  text-decoration: underline;
  text-decoration-color: #e12764;
  color: #000;
}

.my-lease-back-link > svg {
  fill: #e12764;
}
.my-lease-back-link > svg > path {
  fill: #e12764;
}

.my-lease-back-link:hover {
  text-decoration: underline;
  text-decoration-color: #000;
  color: #000;
}

.my-lease-back-link:hover > svg {
  fill: #000;
}

.my-lease-back-link:hover > svg > path {
  fill: #000;
}
