.container-checkbox {
  display: block;
  position: relative;
  padding-left: 45px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's general checkbox */
.container-checkbox input {
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark-large {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  border: 2px solid #6f6f6f;
}

/* Create a custom checkbox */
.checkmark-small {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  border: 2px solid #6f6f6f;
}

/* On mouse-over */
.container-checkbox:hover input ~ .checkmark-large {
  background-color: transparent;
}

/* When the checkbox is checked */
.container-checkbox input:checked ~ .checkmark-large,
.container-checkbox input:checked ~ .checkmark-small {
  border: 2px solid #e12764;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark-large:after,
.checkmark-small:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark2 when checked */
.container-checkbox input:checked ~ .checkmark-large:after,
.container-checkbox input:checked ~ .checkmark-small:after {
  display: block;
}

/* Style the checkmark-large/indicator */
.container-checkbox .checkmark-large:after {
  left: 6px;
  top: 1px;
  width: 7px;
  height: 15px;
  border: solid #e12764;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* Style the checkmark-small/indicator */
.container-checkbox .checkmark-small:after {
  left: 2.5px;
  top: 0px;
  width: 7px;
  height: 10px;
  border: solid #e12764;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
