.thumbnail-card:hover > .thumbnail-card-footer > .active-parent {
  transition-duration: 500ms;
  transition-property: background-color, color, border;
  background-color: #e12764;
  color: #fff;
  border: 1px solid #e12764;
}

.contract-status-lost svg path {
  fill: #e12764;
  stroke: #e12764;

  flex: 1 1 auto
}
