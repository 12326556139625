.rental-premise-image {
  object-fit: contain;
  max-width: 70px !important;
  height: 40px !important;
}

.ant-dropdown-menu-title-content {
  flex: auto;
  display: flex;

  align-items: center;
}
