.selected-view {
  font-weight: 600;
  color: rgba(225, 39, 100);
}

.selected-view::after {
  content: "";
  background-color: rgba(225, 39, 100);
  bottom: -8px;
  width: 100%;
  position: absolute;
  height: 2px;
}
