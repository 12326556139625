.listCard {
  @apply mb-2 px-4 py-3 shadow-none flex flex-row items-center border border-solid border-gray-930 bg-white w-full rounded whitespace-nowrap;
  &:hover {
    @apply cursor-pointer shadow-md;
  }
  & h2 {
    @apply mb-2 font-roboto text-lg;
    line-height: 1.1;
  }
}

.listCardBody {
  @apply w-full flex flex-row self-center;
}

.thumbnailCard {
  @apply flex flex-col bg-white shadow p-4 mt-2 rounded;
  & .thumbnailInfo {
    @apply absolute mt-4 flex flex-row justify-center items-center border border-gray-500 w-8 h-8 ml-auto rounded-full;
    right: -16px;
  }
}

.thumbnailCardHeader {
  & > div {
    @apply flex justify-between w-full;
  }
  & h3 {
    @apply mr-2 font-medium text-lg;
  }
}

.thumbnailCardBody {
  @apply flex flex-col my-2;
}

.thumbnailCardFooter {
  @apply flex mt-auto;
  & span {
    @apply font-normal leading-snug;
  }
  & svg {
    @apply h-6 w-6;
  }
}

.card {
  @apply mb-10 p-3 border border-solid border-gray-930 bg-white;
  & > div {
    @apply flex justify-between;
  }
  & h2 {
    @apply font-roboto text-base font-medium mb-3;
  }
  & svg {
    @apply w-5 h-4;
  }
}

.modal {
  @apply bg-white;
}

.modalHeader {
  @apply flex justify-between mb-4 items-start;
}

.modalTitle {
  @apply text-2xl font-robotoSlab font-normal;
}

.modalBody {
  @apply mb-4;
}

.modalFooter {
  @apply pt-4 flex justify-between items-center;
}

.headerSwitch:focus > div:after {
  @apply border-none !important;
}

.cardHeaderContent {
  @apply flex flex-col justify-between w-full relative;
}
