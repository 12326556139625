.icon-btn {
  padding-left: 10px !important;
  padding-right: 10px !important;
  min-width: 0 !important;
}
.icon-btn > svg {
  width: 25px !important;
  height: 25px !important;
}

.p-0 {
  padding: 0 !important;
}

.w-266px {
  width: 266px !important;
}

.border-secondary {
  border-color: #2CB08A !important;
}
