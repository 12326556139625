.ql-editor:focus {
  background-color: #cdfae2;
}

.ql-editor ol,
.ql-editor ul {
  padding-left: 0;
}

.ql-editor ol li:not(.ql-direction-rtl),
.ql-editor ul li:not(.ql-direction-rtl) {
  padding-left: 1em;
}
